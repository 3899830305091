export const selectGroup = (state, groupId) => {
  if (state.groups.allIds.length === 0) return null;

  return state.groups.byId[groupId];
};

export const selectAllGroups = (state) => {
  if (state.groups.allIds.length === 0) {
    return [];
  }
  return state.groups.allIds
    ?.filter((groupId) => !state.groups.byId[groupId].hasOwnProperty('deleted'))
    .map((groupId) => state.groups.byId[groupId]);
};

// export const selectMyGroupsAsStudent = (state, user) => {
//   return selectAllGroups(state).filter((group) =>
//     group.students.includes(user.id)
//   );
// };

// export const selectMyGroupsAsAdmin = (state, user) => {
//   return selectAllGroups(state).filter((group) =>
//     group.admins.includes(user.id)
//   );
// };

export const selectAllMyGroups = (state, user) => {
  // const myGroupsAsStudent = selectMyGroupsAsStudent(state, user);
  // const myGroupsAsAdmin = selectMyGroupsAsAdmin(state, user);

  // todo: make this shorter
  // if (myGroupsAsStudent.length === 0 && myGroupsAsAdmin.length === 0) {
  //   return [];
  // }
  // if (myGroupsAsAdmin.length === 0) {
  //   return myGroupsAsStudent;
  // }
  // if (myGroupsAsStudent.length === 0) {
  //   return myGroupsAsAdmin;
  // }
  // return [...myGroupsAsStudent, ...myGroupsAsAdmin];
  return selectAllGroups(state);
};

export const selectGroupsTemp = (state) => {
  if (state.groups.allIdsTemp.length === 0) {
    return [];
  }
  return state.groups.allIdsTemp?.map(
    (groupId) => state.groups.byIdTemp[groupId]
  );
};

export const selectAllGroupClients = (state, groupId) => {
  const group = state.groups.byId[groupId];
  if (!group) {
    return [];
  }

  let clean = true;
  const students = group.students.map((studentId) => {
    const student = state.clients.byId[studentId];
    if (!student) {
      clean = false;
    }
    return student;
  });

  if (!clean) {
    return [];
  }
  return students; // already includes admin and owner
};

export const selectGroupOwner = (state, groupId) => {
  const group = state.groups.byId[groupId];
  if (!group) {
    return null;
  }

  return state.clients.byId[group.owner];
};

export const selectPrimaryGroup = (state) => {
  return selectAllGroups(state).find(
    (group) => group.id === state.user.primaryGroup
  );
};

export const findGroupId = (state, user, groupId) => {
  return selectAllMyGroups(state, user).filter(
    (group) => group.groupId === groupId
  );
};

export const selectGroupById = (state, groupId) => {
  return selectAllGroups(state).find((group) => group.groupId === groupId);
};

export const selectMeaninglessGroup = (state) => {
  return selectAllGroups(state).find((group) => group.groupType == 'MEANINGLESS');
};

export const selectHRGroup = (state) => {
  return selectAllGroups(state).find((group) => group.groupType == 'HR');
};

export const selectSubjectGroups = (state) => {
  return selectAllGroups(state).filter((group) => group.groupType == 'SUBJECT')
};

export const selectAfterSchoolGroup = (state) => {
  return selectAllGroups(state).filter((group) => group.groupType == 'AFTER');
};

export const selectClassroomGroup = (state, classroom) => {
  return selectAllGroups(state).find(
    (group) => group.groupId == classroom?.groupId
  );
};
